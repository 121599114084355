<template>
  <!-- eslint-disable -->
  <div id="flow-desginer-app">
    <div
      class="flow-desginer-note-right"
      v-show="analysis.statusID == AnalysisStatusType.Success"
    >
      <v-btn @click="onViewResult" class="primary pr-6">
        <v-icon class="mr-3">mdi-eye-outline</v-icon> View result
      </v-btn>
    </div>
    <h2>{{ schema.title }}</h2>
    <div class="text-center" v-if="isSkeletonLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
        style="margin-top: calc(50vh - 40px)"
      ></v-progress-circular>
    </div>
    <VueBlocksContainer
      v-show="!isSkeletonLoading"
      ref="container"
      :editable="false"
      :blocksContent="schema.blocks"
      :scene.sync="schema.scene"
      class="flow-desginer-container"
    />
    <AnalysisViewer ref="AnalysisViewer" />
  </div>
</template>

<script>
import VueBlocksContainer from "@/components/flow/components/VueBlocksContainer";
import { getAllFlowBlocks, getAnalysisDetail, getTaskDetail } from "./services";
import AnalysisViewer from "@/components/analysis/components/analysis/AnalysisViewer.vue";
import { AnalysisStatusType } from "@/plugins/constant";

export default {
  name: "App",
  components: {
    VueBlocksContainer,
    AnalysisViewer,
  },
  data: function () {
    return {
      AnalysisStatusType,
      timeoutProcess: null,
      isSkeletonLoading: true,
      defaultBlocks: [],
      schema: {
        title: this.$route.query.title,
        blocks: [],
        scene: {
          blocks: [],
          links: [],
          container: {},
        },
      },
      analysis: {
        analysisID: this.$route.query.analysisID,
        statusID: AnalysisStatusType.Queued,
      },
      selectedBlock: null,
      selectedType: "delay",
      modedSceneContainer: null,
    };
  },
  computed: {},
  async mounted() {
    await this.getLatestBlocksFromServer();
    await this.renderAnalysisSchema();
  },
  methods: {
    async getLatestBlocksFromServer() {
      var result = await getAllFlowBlocks();
      if (!result || result.error) {
        this.$toast.error("Cannot get all default flow blocks!");
        return;
      }
      this.defaultBlocks = result;
    },
    async renderAnalysisSchema() {
      await this.renderAnalysisDetail();
      var { taskID, statusID } = this.analysis;
      var result = await getTaskDetail(taskID);
      var { jsonSchema } = result;
      if (!jsonSchema || jsonSchema == "{}") return;
      jsonSchema = JSON.parse(jsonSchema);
      var { blocks, scene } = jsonSchema;
      // find blocks that is in the defaultBlocks but not in this list
      var newBlocks = this.defaultBlocks.filter(
        (b) => !blocks.map((cb) => cb.name).includes(b.name)
      );
      if (this.modedSceneContainer) {
        scene.container = this.modedSceneContainer;
      }
      this.schema.blocks = [...newBlocks, ...blocks];
      this.schema.scene = scene;
      this.$forceUpdate();
      this.isSkeletonLoading = false;
      if (this.timeoutProcess) {
        clearTimeout(this.timeoutProcess);
        this.timeoutProcess = null;
      }
      if (statusID == AnalysisStatusType.Queued) {
        this.timeoutProcess = setTimeout(() => {
          this.renderAnalysisSchema();
        }, 10000);
      }
    },
    async renderAnalysisDetail() {
      var { analysisID } = this.analysis;
      if (!analysisID) return;
      var result = await getAnalysisDetail(analysisID);
      if (!result || result.error) return;
      result.jsonResults = JSON.parse(result.jsonResults);
      this.analysis = result;
    },
    async onViewResult() {
      var { analysisID } = this.analysis;
      if (!analysisID) return;
      var { video_save_path, image_save_path } = this.analysis.jsonResults;
      if (image_save_path) {
        return this.$refs.AnalysisViewer.open(analysisID, "image_save_path");
      }
      if (video_save_path) {
        return this.$refs.AnalysisViewer.open(analysisID, "video_save_path");
      }
    },
  },
  watch: {
    "schema.scene": {
      handler(newValue) {
        this.modedSceneContainer = JSON.parse(
          JSON.stringify(newValue.container)
        );
      },
    },
  },
};
</script>

<style lang="scss">
#flow-desginer-app {
  width: calc(100%);
  height: calc(100%);
  padding: 20px 0 0 20px;
  overflow: hidden;
  display: block;

  .flow-desginer-container {
    width: 100%;
    height: calc(100%);
    margin-top: 10px;
    width: calc(100% - 20px);
  }

  .flow-desginer-note-right {
    position: fixed;
    right: 20px;
  }

  #contextMenu {
    position: absolute;
    z-index: 1000;
    background: white;
    border: 1px solid black;
    padding: 5px;
    margin: 0;

    li {
      &.label {
        color: gray;
        font-size: 90%;
      }
      list-style: none;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
